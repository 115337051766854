<template lang="pug">
  v-container(fluid class="tw-bg-gray-100 fill-height align-content-start "  )
    v-row( no-gutters justify="end" align="center")
        v-col()
            span(class="tw-font-medium tw-text-lg pl-1") Marketing Leads
        v-col(cols="4" )
            v-select( :items="campaigns" multiple  label="Campaign" item-text="name" item-value="id" cache-items v-model="selectedCampaigns" @blur="updateChart" class="tw-font-normal")
              template( v-slot:activator="{ on }")
        v-col(cols="4")
            v-menu( ref="menu" v-model="menu" :close-on-content-click="false"  transition="scale-transition" offset-y max-width="290px" min-width="290px" )
                template( v-slot:activator="{ on }")
                    v-text-field(v-model="formatedDateRange" label="Dates" prepend-icon="mdi-calendar" v-on="on" class="tw-font-normal")
                v-date-picker(v-model="date" class=" tw-z-40" min="2020-01-01" no-title scrollable range pb-0 @change="updateChart")
    v-row(no-gutters)
      v-col()
        div(v-if="total" class="tw-z-0 tw-bg-white tw-rounded tw-border-gray-400 tw-pt-0 tw-pb-5 tw-border-solid tw-border")
          v-container( fluid class="px-6" )
            v-row(no-gutters align="end")
               v-col( cols="auto" class="tw-text-right")
                span(class=" tw-font-bold tw-text-gray-700 tw-text-5xl") {{ total }}

               
            v-row(no-gutters align="start")
              v-col( cols="auto" class="tw--mt-4 tw-text-right tw-pr-12")
                span(class="tw-font-semibold tw-text-sm  tw-text-gray-600 tw-pt-0") Leads Captured

              
           
            
          apexchart(  width="98%" align="center" height="350px" type="bar" :options="options" :series="series")
    
    
    v-dialog(v-model="detailview" fullscreen hide-overlay class="tw-h-screen tw-overflow-hidden" )
      v-container( fluid fill-height pa-0 class=" tw-max-h-screen tw-overflow-hidden " )
        v-row( dense class=" tw-shadow-xl tw-text-white tw-bg-blue-800 tw-px-0 tw-mx-0"  )
          v-col(align-self="center" class="ml-2 tw-font-bold tw-text-gray-300")
            span(class=" tw-capitalize ") {{ detailData.campaign_name }} 
            |for 
            |{{ formatDate(detailData.date)}}
          v-col( cols="auto")
            v-icon( size="26" @click="dialogClose" class="tw-font-bold" color="grey lighten-5" ) mdi-close-circle
        v-row( no-gutters class=" tw-h-full tw-bg-gray-300 tw-shadow-inner ")
          v-col( align="stretch")
            xprt-MktLeads-Main( :campaign="detailData.campaign" :date="detailData.date" )
            
    

</template>
<script>
export default {
  data () {
    return {
      detailview: false,
      detailData: {},
      campaigns: [],
      total:'',
      selectedCampaigns: [],
      date: [ this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      menu: false,
      modal: false,
      options: {
        tooltip: {
          enabled: false
        },
        chart: {
          events: {
            dataPointSelection: this._.debounce(this.showDetails, 200)
          },
          id: 'marketing-leads',
          stacked: true,
          tooltip :{
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false 
            },
          },
        },
        xaxis: {
          type: 'datetime',
          categories: []
        },
      },
      series: [ ]
    }
  },
  computed: {
      date_from: function(){
        return this.date[0]
      },
      date_to: function(){
        return this.date[1]
      },
      formatedDateRange: function(){
         let date = []
         date[0] = this.$moment(this.date[0]).format('MMMM Do YYYY')
         date[1] = this.$moment(this.date[1]).format('MMMM Do YYYY')
         return date.join(' - ')
      },
  },

  mounted() {
    this.updateChart()
    this.$http.get(this.$api_url + 'campaigns/list', {
     headers: {
        'OAuth-Token': this.$store.getters.token
      }
    })
      .then(response => {
        this.campaigns = response.data
      })
  },

  methods: {
    dialogClose() {
      this.detailview = false;
    },
    showDetails: function(e, chartContext, config) {
      this.detailData.campaign = this.series[config.seriesIndex].campaign_id
      this.detailData.campaign_name = this.series[config.seriesIndex].name
      this.detailData.date = this.options.xaxis.categories[config.dataPointIndex]
      this.detailview = true;
      
    },
    updateChart(){
      this.menu = false;

      const headers = {
        'Content-Type': 'application/json',
        'OAuth-Token': this.$store.getters.token 
      }

      this.$http.post(this.$api_url + 'leads/stats', {
             date_from: this.date_from,
             date_to: this.date_to,
             campaign: this.selectedCampaigns,
     }, { headers: headers } )
      .then(response => {
         this.options = {
                ...this.options,
                ...{
                    xaxis: {
                        type: 'datetime',
                        categories: response.data['categories'],
                    },
                     //series: response.data['series']
                },               
            },
          this.total = response.data['total']
          this.series = response.data['series'];
        //this.loading = false
      })

    },
    formatDate: function(date){
      return this.$moment(date).format('ll')
    }
  },
}
</script>
<style lang="stylus">
#core-view {
  padding: 0px;
  height: 100%;
}
</style>